.table {
	font-size: 15px;
	color: var(--body-clr);
	margin: 0;
	* {
		border-color: var(--border-clr);
	}
	@include breakpoint(xl) {
		.badge {
			min-width: 70px;
		}
	}
	thead {
		background: var(--primary-clr);
		th {
			padding: 17px 0;
			border: none;
			color: var(--white-clr);
			font-family: var(--heading-fotns);
		}
		margin-left: -1px;
		margin-right: -1px;
	}
	border-left: 1px solid var(--border-clr);
	border-right: 1px solid var(--border-clr);
	tbody {
		tr {
			td {
				padding: 17px 0;
			}
		}
	}
	text-align: center;
}
@include breakpoint(max-xxl) {
	.table--mobile-xxl {
		padding-right: 5px;
		&:not(.no--scroll) {
			max-height: 70vh;
			overflow-x: hidden;
			overflow-y: auto;
			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 6px rgba(var(--title-rgb), 0.3);
				width: 4px;
			}
			&::-webkit-scrollbar {
				width: 4px;
			}
			&::-webkit-scrollbar-thumb {
				background-color: var(--base-clr);
				outline: 1px solid var(--base-clr);
				width: 4px;
			}
		}
		.table {
			width: 100%;
			border-left: 1px solid var(--border-clr);
			border-right: 1px solid var(--border-clr);
			border-top: 1px solid var(--border-clr);
			text-align: left;
			thead {
				display: none;
			}
			tbody {
				tr {
					display: block;
					td {
						display: flex;
						justify-content: space-between;
						align-items: center;
						&::before {
							content: attr(data-label);
							font-family: var(--heading-fonts);
							font-weight: 500;
							color: var(--title-clr);
						}
						border: none;
						padding: 3px 0;
					}
					border-bottom: 1px solid var(--border-clr);
					padding: 10px 15px;
					&:nth-child(even) {
						background: var(--section-bg);
					}
				}
			}
		}
	}
}
@include breakpoint(max-xl) {
	.table--mobile-xl {
		padding-right: 5px;
		&:not(.no--scroll) {
			max-height: 70vh;
			overflow-x: hidden;
			overflow-y: auto;
			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 6px rgba(var(--title-rgb), 0.3);
				width: 4px;
			}
			&::-webkit-scrollbar {
				width: 4px;
			}
			&::-webkit-scrollbar-thumb {
				background-color: var(--base-clr);
				outline: 1px solid var(--base-clr);
				width: 4px;
			}
		}
		.table {
			width: 100%;
			color: var(--body-clr);
			border-left: 1px solid var(--border-clr);
			border-right: 1px solid var(--border-clr);
			border-top: 1px solid var(--border-clr);
			text-align: left;
			thead {
				display: none;
			}
			tbody {
				tr {
					display: block;
					td {
						display: flex;
						justify-content: space-between;
						align-items: center;
						&::before {
							content: attr(data-label);
							font-family: var(--heading-fonts);
							font-weight: 500;
							color: var(--title-clr);
						}
						border: none;
						padding: 3px 0;
					}
					border-bottom: 1px solid var(--border-clr);
					padding: 10px 15px;
					&:nth-child(even) {
						background: var(--section-bg);
					}
				}
			}
		}
	}
}
@include breakpoint(max-lg) {
	.table--mobile-lg {
		padding-right: 5px;
		&:not(.no--scroll) {
			max-height: 70vh;
			overflow-x: hidden;
			overflow-y: auto;
			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 6px rgba(var(--title-rgb), 0.3);
				width: 4px;
			}
			&::-webkit-scrollbar {
				width: 4px;
			}
			&::-webkit-scrollbar-thumb {
				background-color: var(--base-clr);
				outline: 1px solid var(--base-clr);
				width: 4px;
			}
		}
		.table {
			width: 100%;
			color: var(--body-clr);
			border-left: 1px solid var(--border-clr);
			border-right: 1px solid var(--border-clr);
			border-top: 1px solid var(--border-clr);
			text-align: left;
			thead {
				display: none;
			}
			tbody {
				tr {
					display: block;
					td {
						display: flex;
						justify-content: space-between;
						align-items: center;
						&::before {
							content: attr(data-label);
							font-family: var(--heading-fonts);
							font-weight: 500;
							color: var(--title-clr);
						}
						border: none;
						padding: 3px 0;
					}
					border-bottom: 1px solid var(--border-clr);
					padding: 10px 15px;
					&:nth-child(even) {
						background: var(--section-bg);
					}
				}
			}
		}
	}
}
@include breakpoint(max-md) {
	.table--mobile-md {
		padding-right: 5px;
		&:not(.no--scroll) {
			max-height: 70vh;
			overflow-x: hidden;
			overflow-y: auto;
			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 6px rgba(var(--title-rgb), 0.3);
				width: 4px;
			}
			&::-webkit-scrollbar {
				width: 4px;
			}
			&::-webkit-scrollbar-thumb {
				background-color: var(--base-clr);
				outline: 1px solid var(--base-clr);
				width: 4px;
			}
		}
		.table {
			width: 100%;
			color: var(--body-clr);
			border-left: 1px solid var(--border-clr);
			border-right: 1px solid var(--border-clr);
			border-top: 1px solid var(--border-clr);
			text-align: left;
			thead {
				display: none;
			}
			tbody {
				tr {
					display: block;
					td {
						display: flex;
						justify-content: space-between;
						align-items: center;
						&::before {
							content: attr(data-label);
							font-family: var(--heading-fonts);
							font-weight: 500;
							color: var(--title-clr);
						}
						border: none;
						padding: 3px 0;
					}
					border-bottom: 1px solid var(--border-clr);
					padding: 10px 15px;
					&:nth-child(even) {
						background: var(--section-bg);
					}
				}
			}
		}
	}
}
@include breakpoint(max-sm) {
	.table--mobile-sm {
		padding-right: 5px;
		&:not(.no--scroll) {
			max-height: 70vh;
			overflow-x: hidden;
			overflow-y: auto;
			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 6px rgba(var(--title-rgb), 0.3);
				width: 4px;
			}
			&::-webkit-scrollbar {
				width: 4px;
			}
			&::-webkit-scrollbar-thumb {
				background-color: var(--base-clr);
				outline: 1px solid var(--base-clr);
				width: 4px;
			}
		}
		.table {
			width: 100%;
			color: var(--body-clr);
			border-left: 1px solid var(--border-clr);
			border-right: 1px solid var(--border-clr);
			border-top: 1px solid var(--border-clr);
			text-align: left;
			thead {
				display: none;
			}
			tbody {
				tr {
					display: block;
					td {
						display: flex;
						justify-content: space-between;
						align-items: center;
						&::before {
							content: attr(data-label);
							font-family: var(--heading-fonts);
							font-weight: 500;
							color: var(--title-clr);
						}
						border: none;
						padding: 3px 0;
					}
					border-bottom: 1px solid var(--border-clr);
					padding: 10px 15px;
					&:nth-child(even) {
						background: var(--section-bg);
					}
				}
			}
		}
	}
}
.cmn--media {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	max-width: 200px;
	margin: 0 auto;
	@include breakpoint(max-lg) {
		margin-right: 0;
	}
	img {
		width: 40px;
		height: 40px;
		object-fit: cover;
		@include border-radius(50%);
	}
	.subtitle {
		padding-left: 15px;
		font-size: 16px;
		font-weight: 500;
	}
	@include breakpoint(max-sm) {
		img {
			width: 30px;
			height: 30px;
		}
		.subtitle {
			font-size: 15px;
			padding-left: 10px;
		}
	}
}
.transaction--buttons {
	display: flex;
	flex-wrap: wrap;
	max-width: 600px;
	margin: -5px auto 10px;
	justify-content: center;
	border: none;
	padding: 0;
	.cmn--btn {
		min-width: 150px;
		flex-grow: 1;
		margin: 5px;
		@include border-radius(25px);
		&:hover {
			color: var(--white-clr);
			background: var(--base-clr);
		}
		&:not(.active) {
			background: transparent;
			color: var(--base-clr);
			&:hover {
				background: transparent;
				color: var(--base-clr);
			}
		}
	}
	@include breakpoint(max-sm) {
		margin: -5px;
	}
}
.tab-content {
	.tab-pane {
		&.active {
			animation: 0.3s fadeInUp;
			-webkit-animation: 0.3s fadeInUp;
			-moz-animation: 0.3s fadeInUp;
		}
	}
}
