$bootstrap-sass-asset-helper: false !default;

$title: #fff;

$success-color: #0ead69;
$secondary-color: #668799;
$danger-color: #d90429;
$warning-color: #ee9b00;
$info-color: #00b4d8;
$primary-color: #0c67a3;
$ash-color: #fafafa;
$white: #fff;
$lallu-color: #e883ae;
$dark-color: #192a56;
$dark-bg: #131b32;
$light-color: #daddff;
$black: #000;
$section-bg-2: #fafafa;
$whatsapp: #25d366;
$facebook: #3b5998;
$twitter: #55acee;
$linkedin: #007bb5;
$pinterest: #007bb5;
$skype: #2fb7df;
$youtube: #cd201f;
$google: #dd4b39;
$camera: #e4405f;
$instagram: #e4405f;
$tumblr: #34465d;
$behance: #1769ff;
$star: #e9ba17;
$green: #0be881;
$green-h: #0fbcf9;
$red: #f53b57;
$yellow: #dec32b;
$nell: #212fb4;
$navy: #d8e0f3;
$placeholder: #a1a1a2;
$name-dec: #3f485d;
$futbol: #db3776;
$vimeo: #1ab7ea;
$dribble: #ea4c89;

$transition: all ease 0.3s;

// $base2: #92278f;
$base2: #fb8500;
$base3: #8dc63f;
$base4: #f4978e;

:root {
	--base-clr: #00bfff;
	--base-rgb: 0, 191, 255;
	--body-clr: #ece7ff;
	--light-clr: #ece7ff;
	--title-clr: #fff;
	--title-rgb: 255, 255, 255;
	// --body-bg: #003e70;
	// --section-bg: #023c6b;
	--body-bg: #00192d;
	--section-bg: #001e33;
	--section-light: #f3f9ff;
	// --dark-clr: #00447a;
	--dark-clr: #001e33;
	--dark-rgb: 0, 30, 51;
	--white-clr: #ffffff;
	--white-rgb: 255, 255, 255;
	--primary-clr: #0c67a3;
	--primary-rgb: 12, 103, 163;
	--border-clr: rgba(255, 255, 255, 0.1);
	--border-light: rgba(255, 255, 255, 0.1);
	--border-light-only: rgba(255, 255, 255, 0.1);
	--border-dark: #302474;
	--border-dark-only: transparent;

	--heading-fonts: "Poppins", sans-serif;
	--body-fonts: "Roboto", sans-serif;
	--base-shadow-sm: 0 0 1px var(--base-clr), 0 0 3px var(--base-clr);
	--base-shadow: 0 0 1px var(--base-clr), 0 0 2px var(--base-clr),
		0 0 3px var(--base-clr), 0 0 5px var(--base-clr), 0 0 7px var(--base-clr);
	--shadow: 0 0 5px rgba(var(--title-rgb), 0.2);
}
