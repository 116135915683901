%flex {
	display: flex;
	flex-wrap: wrap;
}
%center {
	top: 50%;
	left: 50%;
	@include transform(translate(-50%, -50%));
	position: absolute;
	content: "";
}
.pt-80 {
	padding-top: 80px;
}
.pb-80 {
	padding-bottom: 80px;
}
.pt-100 {
	padding-top: 70px;
}
.pb-100 {
	padding-bottom: 70px;
}
.pt-50 {
	padding-top: 35px;
}
.pb-50 {
	padding-bottom: 35px;
}
@include breakpoint(lg) {
	.pt-100 {
		padding-top: 100px;
	}
	.pb-100 {
		padding-bottom: 100px;
	}
	.pt-50 {
		padding-top: 50px;
	}
	.pb-50 {
		padding-bottom: 50px;
	}
}
.overlayer {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(var(--dark-rgb), 0.7);
	z-index: 99;
	display: none;
	&.active {
		display: block;
		animation-name: fadeIn;
		-webkit-animation-name: fadeIn;
		-moz-animation-name: fadeIn;
		animation-duration: 0.3s;
		-webkit-animation-duration: 0.3s;
		-moz-animation-duration: 0.3s;
	}
}
.pagination {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: -5px;
	margin-top: 15px;
	li {
		padding: 5px;
		a,
		span {
			width: 35px;
			height: 35px;
			line-height: 35px;
			padding: 0;
			text-align: center;
			@include border-radius(4px);
			background: var(--section-bg);
			color: var(--title-clr);
			border: 1px solid var(--border-clr);
			&.active {
				background: var(--primary-clr);
				border-color: var(--primary-clr);
				color: var(--white-clr);
			}
			&.disabled {
				background: rgba(var(--base-rgb), 0.1);
			}
		}
	}
}
.owl-dots {
	display: flex;
	flex-wrap: wrap;
	margin: -3px;
	margin-top: 20px;
	justify-content: center;
	.owl-dot {
		width: 13px;
		height: 13px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include border-radius(50%);
		@include transition($transition);
		margin: 3px;
		&.active {
			@include border-radius(50%);
			border: 1px solid var(--base-clr);
			border-color: var(--base-clr);
		}
		span {
			background: var(--base-clr);
			width: 5px;
			height: 5px;
			@include border-radius(50%);
		}
	}
}
.toTopBtn {
	position: fixed;
	bottom: -60px;
	right: 30px;
	width: 40px;
	line-height: 40px;
	display: block;
	text-align: center;
	color: var(--base-clr);
	@include border-radius(5px);
	@include transition($transition);
	cursor: pointer;
	z-index: 3;
	background: var(--title-clr);
	&.active {
		bottom: 30px;
	}
}
.loader {
	position: fixed;
	inset: 0;
	background: var(--body-bg);
	z-index: 99999;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sec-title {
	position: relative;
	margin-bottom: 40px;
}

.sec-title .title {
	position: relative;
	font-weight: 500;
	z-index: 1;
	display: inline-block;
	padding-left: 30px;
	color: var(--base-clr);
	margin-top: 26px;
}

.sec-title .title:before {
	position: absolute;
	content: "\f010";
	font-weight: 600;
	left: 0px;
	top: -2px;
	color: var(--base-clr);
	font-family: "Font Awesome 5 Free";
}

.sec-title .title:after {
	position: absolute;
	content: "";
	left: -20px;
	top: -25px;
	right: 0px;
	width: 238px;
	height: 60px;
	mask: url(./img/vector.png) no-repeat;
	-webkit-mask: url(./img/vector.png) no-repeat;
	background: var(--base-clr);
}

.sec-title .text {
	margin-top: var(--margin-top-15);
}

.sec-title h2 {
	margin-bottom: 18px;
	margin-top: var(--margin-top-15);
}

.sec-title h2 span {
	color: var(--main-color);
}

.sec-title.light .text,
.sec-title.light h2 {
	color: var(--white-color);
}

.sec-title.light .title {
	color: var(--white-color);
	border-color: var(--white-color);
}

.sec-title.centered {
	max-width: 720px;
	margin-left: auto;
	margin-right: auto;
	text-align: center !important;
}

.sec-title.centered .separator {
	margin: 0 auto;
}
