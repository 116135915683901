.banner-overlay {
	position: relative;
	&::before {
		inset: 0;
		position: absolute;
		content: "";
		background: rgb(0, 16, 32, 0.8);
	}
}
.banner-section {
	padding: 280px 0 235px;
	overflow: hidden;
	background-position: top center;
	@include breakpoint(max-xl) {
		padding: 246px 0 235px;
	}
	@include breakpoint(max-lg) {
		padding: 276px 0 200px;
	}
	@include breakpoint(max-sm) {
		padding: 240px 0 180px;
	}
	.banner-wrapper {
		position: relative;
	}
}
.bg--overlay {
	position: relative;
	&::before {
		inset: 0;
		position: absolute;
		content: "";
		background: rgb(0, 16, 32, 0.8);
	}
}
.banner-wrapper {
	@extend %flex;
	align-items: center;
	.banner-thumb {
		width: calc(100% - 620px);
		img {
			max-width: 820px;
		}
	}
	@include breakpoint(max-xxl) {
		.banner-thumb {
			img {
				max-width: 700px;
			}
		}
	}
	@include breakpoint(max-xl) {
		.banner-thumb {
			img {
				max-width: 600px;
			}
		}
	}
	@include breakpoint(max-lg) {
		.banner-content {
			max-width: 100%;
			width: 100%;
			p {
				max-width: 100%;
			}
		}
		.banner-thumb {
			width: 100%;
			max-width: 600px;
			margin-top: 35px;
			img {
				max-width: 100%;
			}
		}
	}
}
.banner-content {
	max-width: 820px;
	position: relative;
	z-index: 9;
	text-align: center;
	margin: 0 auto;
	.banner-title {
		text-transform: capitalize;
		color: var(--white-clr);
		margin-bottom: 20px;
	}
	p {
		color: $light-color;
		max-width: 640px;
		margin-left: auto;
		margin-right: auto;
		@include breakpoint(sm) {
			font-size: 20px;
			line-height: 1.6;
		}
	}
	.btn__grp {
		justify-content: center;
	}
	@include breakpoint(sm) {
		.banner-title {
			margin-bottom: 30px;
		}
		p {
			font-size: 18px;
		}
		.btn__grp {
			margin-top: 35px;
		}
	}
}
.lightbox-container {
	z-index: 999;
}
.mt--120 {
	margin-top: -120px !important;
}
.hero-section {
	padding: 236px 0 90px;
	@include breakpoint(max-md) {
		padding: 246px 0 70px;
	}
}
.hero-content {
	position: relative;
	z-index: 1;
	.hero-title {
		color: var(--white-clr);
	}
	text-align: center;
}
.breadcrumb {
	padding: 0;
	margin: 0;
	justify-content: center;
	margin-top: 10px;
	li {
		color: var(--base-clr);
		font-family: var(--heading-fonts);
		display: flex;
		align-items: center;
		&:not(:last-child) {
			&::after {
				content: "-";
				display: inline-block;
				margin: 0 10px;
			}
		}
		a {
			color: var(--white-clr);
		}
	}
}

.feature-item {
	@include transition($transition);
	border: 1px solid var(--border-clr);
	@include border-radius(5px);
	background: var(--section-bg);
	padding: 30px 20px;
	position: relative;
	z-index: 1;
	height: 100%;
	@include breakpoint(lg) {
		@include breakpoint(max-xl) {
			font-size: 14px;
		}
	}
	&__icon {
		font-size: 24px;
		color: var(--base-clr);
		background: rgba(var(--base-rgb), 0.1);
		border: 1px solid rgba(var(--base-rgb), 0.2);
		width: 60px;
		line-height: 60px;
		height: 60px;
		@include border-radius(50%);
		text-align: center;
		margin: 0 auto 25px;
		@include transition($transition);
	}
	text-align: center;
	&__cont {
		&-title {
			margin-bottom: 20px;
			color: var(--base-clr);
		}
	}
	&:hover {
		box-shadow: 0 0 7px rgba(var(--title-rgb), 0.2);
		@include transform(translateY(-5px));
		.feature-item__icon {
			border-color: rgba(var(--base-rgb), 0.2) !important;
			background: var(--base-clr) !important;
			color: var(--white-clr) !important;
			@include transform(translateY(-5px));
		}
	}
	@include breakpoint(max-md) {
		@include breakpoint(sm) {
			font-size: 14px;
		}
	}
	@include breakpoint(max-sm) {
		max-width: 450px;
		margin: 0 auto;
	}
}
div[class*="col"] {
	&:nth-of-type(4n + 2) {
		.feature-item {
			&__icon {
				border-color: rgba($base2, 0.1) !important;
				background: rgba($base2, 0.1) !important;
				color: $base2 !important;
			}
			&__cont-title {
				color: $base2 !important;
			}
			&:hover {
				.feature-item__icon {
					border-color: rgba($base2, 0.2) !important;
					background: $base2 !important;
					color: var(--white-clr) !important;
				}
			}
		}
	}
	&:nth-of-type(4n + 3) {
		.feature-item {
			&__icon {
				border-color: rgba($base3, 0.2) !important;
				background: rgba($base3, 0.1) !important;
				color: $base3 !important;
			}
			&__cont-title {
				color: $base3 !important;
			}
			&:hover {
				.feature-item__icon {
					border-color: rgba($base3, 0.2) !important;
					background: $base3 !important;
					color: var(--white-clr) !important;
				}
			}
		}
	}
	&:nth-of-type(4n + 4) {
		.feature-item {
			&__icon {
				border-color: rgba($base4, 0.2);
				background: rgba($base4, 0.1);
				color: $base4;
			}
			&__cont-title {
				color: $base4 !important;
			}
			&:hover {
				.feature-item__icon {
					border-color: rgba($base4, 0.2) !important;
					background: $base4 !important;
					color: var(--white-clr) !important;
				}
			}
		}
	}
}
