/*Prealoader*/
$main-color: #d3d3d2;
$main-width: 3px;
$main-circle-size: 8px;
$animation-duration: 2.5s;
$animation-easing: linear;

$line-width: 8px;

.loader-container {
	background-color: var(--section-bg);
	margin: 0 auto;
	margin-top: 50px;
	width: 100%;
	max-width: 200px;
	aspect-ratio: 1;
	border-radius: 50%;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
}

.loader-chart {
	width: 100px;
	height: 100px;
	position: relative;
}
.loader-horizontal,
.loader-vertical {
	position: absolute;
	left: 0;
	bottom: 0;
	background-color: $main-color;
	height: $main-width;

	&:before {
		content: "";
		width: $main-circle-size;
		height: $main-circle-size;
		border-radius: 50%;
		border: $main-width solid $main-color;
		position: absolute;
	}
}
.loader-horizontal {
	height: $main-width;
	width: 100%;
	animation: horizontal $animation-duration $animation-easing infinite;

	&:before {
		margin-right: -$main-circle-size -$main-width;
		margin-top: -$main-circle-size/2-$main-width/2;
		top: 0;
		right: 0;
		animation: horizontal-circle $animation-duration $animation-easing
			infinite;
	}
}
.loader-vertical {
	width: $main-width;
	height: 100%;
	animation: vertical $animation-duration $animation-easing infinite;

	&:before {
		margin-left: -$main-circle-size/2-$main-width/2;
		margin-top: -$main-circle-size -$main-width;
		top: 0;
		left: 0;
		animation: vertical-circle $animation-duration $animation-easing infinite;
	}
}

.loader-line1,
.loader-line2,
.loader-line3,
.loader-line4 {
	background-color: var(--base-clr);
	width: $line-width;
	height: 50%;
	position: absolute;
	left: 0;
	bottom: 10px;
	border-radius: $line-width;
}
.loader-line1 {
	left: 20%;
	height: 62%;
	animation: line1 $animation-duration $animation-easing infinite;
}
.loader-line2 {
	left: 40%;
	height: 38%;
	animation: line2 $animation-duration $animation-easing infinite;
}
.loader-line3 {
	left: 60%;
	height: 23%;
	animation: line3 $animation-duration linear infinite;
}
.loader-line4 {
	left: 80%;
	height: 37%;
	animation: line4 $animation-duration linear infinite;
}

@keyframes vertical {
	0% {
		height: 0;
	}
	10% {
		height: 50%;
	}
	20%,
	90% {
		height: 100%;
	}
	100% {
		height: 0%;
	}
}

@keyframes horizontal {
	0% {
		width: 0;
	}
	10% {
		width: 25%;
	}
	20% {
		width: 75%;
	}
	30%,
	90% {
		width: 100%;
	}
	100% {
		width: 0;
	}
}

@keyframes horizontal-circle {
	0% {
		opacity: 0;
		transform: translateZ(0) scale(0);
	}
	10% {
	}
	20% {
		opacity: 0;
	}
	30%,
	90% {
		opacity: 1;
		transform: translateZ(0) scale(1);
	}
	100% {
		opacity: 0;
		transform: translateZ(0) scale(0);
	}
}

@keyframes vertical-circle {
	0% {
		opacity: 0;
		transform: translateZ(0) scale(0);
	}
	10% {
		opacity: 0;
	}
	20%,
	90% {
		opacity: 1;
		transform: translateZ(0) scale(1);
	}
	100% {
		opacity: 0;
		transform: translateZ(0) scale(0);
	}
}

@keyframes line1 {
	0% {
		height: 0;
	}
	10% {
	}
	20% {
	}
	30% {
		height: 0;
	}
	40% {
		height: 7%;
	}
	50% {
		height: 38%;
	}
	60% {
		height: 62%;
	}
	70% {
		height: 89%;
	}
	80%,
	90% {
		height: 62%;
	}
	100% {
		height: 0%;
	}
}

@keyframes line2 {
	0% {
		height: 0;
	}
	10% {
	}
	20% {
	}
	30% {
	}
	40% {
		height: 0;
	}
	50% {
		height: 26%;
	}
	60% {
		height: 38%;
	}
	70% {
		height: 23%;
	}
	80%,
	90% {
		height: 38%;
	}
	100% {
		height: 0%;
	}
}

@keyframes line3 {
	0% {
		height: 0;
	}
	10% {
	}
	20% {
	}
	30% {
	}
	40% {
		height: 0;
	}
	50% {
		height: 9%;
	}
	60% {
		height: 23%;
	}
	70% {
		height: 61%;
	}
	80%,
	90% {
		height: 23%;
	}
	100% {
		height: 0%;
	}
}

@keyframes line4 {
	0% {
		height: 0;
	}
	10% {
	}
	20% {
	}
	30% {
	}
	40% {
		height: 0;
	}
	50% {
		height: 45%;
	}
	60% {
		height: 37%;
	}
	70% {
		height: 83%;
	}
	80%,
	90% {
		height: 37%;
	}
	100% {
		height: 0%;
	}
}
